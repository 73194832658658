import React, { useEffect } from "react";
import Banner from "../components/contact/banner";
import GetInTouch from "../components/contact/getInTouch";
import Map from "../components/contact/map";

const ContactUs = () => {
  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it runs only once

  return (
    <>
      <Banner />
      <GetInTouch />
      <Map />
    </>
  );
};

export default ContactUs;
