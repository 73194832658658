import React from "react";
import "./servicesContent.scss";
import service1 from "../../../assets/images/serviceimg1.webp";
import service2 from "../../../assets/images/serviceimg2.webp";
import service3 from "../../../assets/images/serviceimg3.webp";
import service4 from "../../../assets/images/serviceimg4.webp";
import service5 from "../../../assets/images/serviceimg5.webp";
import service6 from "../../../assets/images/serviceimg6.webp";
import tick2 from "../../../assets/images/blacktick.png";
import tick from "../../../assets/images/tick.png";
import SubmitForm from "../../home/submitForm";
import backimg from "../../../assets/images/aboutbackpic.png";
import { useNavigate } from "react-router-dom";
const ServicesContent = () => {
  const navigate = useNavigate();
  return (
    <div className="services-top-main">
      <div className="services-main-container">
        <div data-aos="zoom-out-up" className="our-serviceshead-container">
          <div className="our-left">
            <h2>Our</h2>
          </div>
          <div className="our-right">
            <h2>Services</h2>
          </div>
        </div>

        <div className="services-card-maincontainer">
          <div
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="service-card-left"
          >
            <div className="service-btn">
              <p> SERVICE 1</p>
            </div>
            <h2>Blockchain Development</h2>
            <p>
              Metaloop Solution excels in creating decentralized Solutions,
              leveraging blockchain technology to secure transactions and
              streamline processes, ensuring transparency and trust in data
              management.
            </p>
            <div className="service-left-3">
              <div className="left3-row">
                <img src={tick} alt="" />
                <h3>Smart Contracts</h3>
              </div>
              <div className="left3-row">
                <img src={tick} alt="" />
                <h3>Decentralized Applications.</h3>
              </div>
              <div className="left3-row">
                <img src={tick} alt="" />
                <h3>Security</h3>
              </div>
            </div>

            <div className="getin-touch-btn">
              <button onClick={() => navigate("/contact")}>Get In Touch</button>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="service-card-right"
          >
            <img src={service1} alt="" />
          </div>
        </div>
        <div className="services-card-maincontainer">
          <div
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="service-card-left"
          >
            <div className="service-btn">
              <p> SERVICE 2</p>
            </div>
            <h2>Artificial Intelligence</h2>
            <p>
              At Metaloop Solution, our AI expertise drives natural language
              processing, image recognition, and machine learning innovation. We
              develop intelligent systems that enhance automation and
              decision-making for our clients.
            </p>
            <div className="service-left-3">
              <div className="left3-row">
                <img src={tick2} alt="" />
                <h3>Natural Language Processing (NLP)</h3>
              </div>
              <div className="left3-row">
                <img src={tick2} alt="" />
                <h3>Computer Vision</h3>
              </div>
              <div className="left3-row">
                <img src={tick2} alt="" />
                <h3>Machine Learning</h3>
              </div>
            </div>

            <div className="getin-touch-btn">
              <button onClick={() => navigate("/contact")}>Get In Touch</button>
            </div>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="service-card-right"
          >
            <img src={service2} alt="" />
          </div>
        </div>

        <div className="services-card-maincontainer">
          <div
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="service-card-left"
          >
            <div className="service-btn">
              <p> SERVICE 3</p>
            </div>
            <h2>Metaverse</h2>
            <p>
              As pioneers in the metaverse space, Metaloop Solution connects
              users through augmented reality, virtual reality, and the
              internet. We create shared virtual spaces, blurring boundaries
              between physical and digital realms for unparalleled interactive
              experiences
            </p>
            <div className="service-left-3">
              <div className="left3-row">
                <img src={tick} alt="" />
                <h3>Virtual Social Spaces</h3>
              </div>
              <div className="left3-row">
                <img src={tick} alt="" />
                <h3>Digital Assets and Economy</h3>
              </div>
              <div className="left3-row">
                <img src={tick} alt="" />
                <h3>Cross-Platform Integration</h3>
              </div>
            </div>

            <div className="getin-touch-btn">
              <button onClick={() => navigate("/contact")}>Get In Touch</button>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="service-card-right"
          >
            <img src={service3} alt="" />
          </div>
        </div>
        <div className="services-card-maincontainer">
          <div
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="service-card-left"
          >
            <div className="service-btn">
              <p> SERVICE 4</p>
            </div>
            <h2>Game Development</h2>
            <p>
              Metaloop Solution is a leader in creative game development, using
              new technologies like virtual reality and real-time graphics to
              create immersive and entertaining gaming experiences for diverse
              platforms.
            </p>
            <div className="service-left-3">
              <div className="left3-row">
                <img src={tick2} alt="" />
                <h3>Graphics and Animation</h3>
              </div>
              <div className="left3-row">
                <img src={tick2} alt="" />
                <h3>Multiplatform Development</h3>
              </div>
              <div className="left3-row">
                <img src={tick2} alt="" />
                <h3>Game Design</h3>
              </div>
            </div>

            <div className="getin-touch-btn">
              <button onClick={() => navigate("/contact")}>Get In Touch</button>
            </div>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="service-card-right"
          >
            <img src={service4} alt="" />
          </div>
        </div>
        <div className="services-card-maincontainer">
          <div
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="service-card-left"
          >
            <div className="service-btn">
              <p> SERVICE 5</p>
            </div>
            <h2>Devops</h2>
            <p>
              Metaloop Solution implements DevOps practices to accelerate
              software development and deployment. Our collaborative approach,
              automation, and continuous delivery ensure efficiency and
              high-quality outcomes for our clients' projects
            </p>
            <div className="service-left-3">
              <div className="left3-row">
                <img src={tick} alt="" />
                <h3>Continuous Integration (CI)</h3>
              </div>
              <div className="left3-row">
                <img src={tick} alt="" />
                <h3>Continuous Delivery (CD)</h3>
              </div>
              <div className="left3-row">
                <img src={tick} alt="" />
                <h3>Infrastructure as Code (IaC)</h3>
              </div>
            </div>

            <div className="getin-touch-btn">
              <button onClick={() => navigate("/contact")}>Get In Touch</button>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="service-card-right"
          >
            <img src={service5} alt="" />
          </div>
        </div>
        <div className="services-card-maincontainer">
          <div
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="service-card-left"
          >
            <div className="service-btn">
              <p> SERVICE 6</p>
            </div>
            <h2>Sales & Marketing</h2>
            <p>
              Sales and marketing involve strategies to promote and sell
              products or services. In the digital age, it includes online and
              offline tactics, leveraging social media, content marketing, and
              data analytics to enhance customer engagement and drive business
              growth.
            </p>
            <div className="service-left-3">
              <div className="left3-row">
                <img src={tick2} alt="" />
                <h3>Digital Marketing</h3>
              </div>
              <div className="left3-row">
                <img src={tick2} alt="" />
                <h3>Customer Relationship Management (CRM)</h3>
              </div>
              <div className="left3-row">
                <img src={tick2} alt="" />
                <h3>Data Analytics</h3>
              </div>
            </div>

            <div className="getin-touch-btn">
              <button onClick={() => navigate("/contact")}>Get In Touch</button>
            </div>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="service-card-right"
          >
            <img src={service6} alt="" />
          </div>
        </div>
      </div>
      <div className="services-background">
        <img src={backimg} alt="" />
      </div>
      <SubmitForm />
    </div>
  );
};

export default ServicesContent;
