import React from "react";
import { Link } from "react-router-dom"; 
import { FaEye } from "react-icons/fa"; 

const ProjectCards = (props) => {
  const { portfolioLink, cardBg, projectName, projectDetails } = props;

  
  const isLinkAvailable = portfolioLink ? true : false;

  return (
    <div className="portfolioCards">

      {isLinkAvailable ? (
        <Link 
          to={portfolioLink} 
          className="portfolioCardLink"
          target="_blank" 
          rel="noopener noreferrer"
        >
          <div className="cardContent" style={{ cursor: 'pointer' }}> 
            <img src={cardBg} alt={projectName} />
            <div className="eyeIcon">
              <FaEye />
            </div>
            <div className="projInfo">
              <h4>{projectName}</h4>
              <p>{projectDetails}</p>
            </div>
          </div>
        </Link>
      ) : (
   
        <div className="portfolioCard">
          <div className="cardContent">
            <img src={cardBg} alt={projectName} />
            <div className="projInfo">
              <h4>{projectName}</h4>
              <p>{projectDetails}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectCards;
