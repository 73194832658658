import React, { useEffect } from "react";
import ProjectCards from "./projectCards";

const LatestProjects = () => {
  const bgimg1 = "../../../images/portfolio/resumeLanding.webp";
  const bgimg2 = "../../../images/portfolio/dispatchLanding.png";
  const bgimg3 = "../../../images/portfolio/dmBotLanding.webp";
  const bgimg4 = "../../../images/portfolio/simpliLanding.webp";
  const bgimg5 = "../../../images/portfolio/nextGen.webp";
  const bgimg6 = "../../../images/portfolio/neoAILanding.webp";
  const bgimg7 = "../../../images/portfolio/metaMuzeLanding.webp";
  const bgimg8 = "../../../images/portfolio/domainLanding.webp";
  const bgimg9 = "../../../images/portfolio/trunkLanding.webp";
  const bgimg10 = "../../../images/portfolio/vitraLanding.webp";
  const bgimg11 = "../../../images/portfolio/autored.webp";
  const bgimg12 = "../../../images/portfolio/queryflo.png";
  const bgimg13 = "../../../images/portfolio/misterai.webp";
  const bgimg14 = "../../../images/portfolio/lawvocate.webp";
  const bgimg15 = "../../../images/portfolio/trekverse.webp";
  const bgimg16 = "../../../images/portfolio/dstreak.webp";
  const bgimg17 = "../../../images/portfolio/toy.webp";
  const bgimg18 = "../../../images/portfolio/tekonecta.webp";
  const bgimg19 = "../../../images/portfolio/hostguru.webp";

  const projName1 = "Resume";
  const projName2 = "Dispatch AI";
  const projName3 = "DM Bot";
  const projName4 = "Simpli RFP";
  const projName5 = "NEXGEN";
  const projName6 = "NEO AI";
  const projName7 = "Metamuze";
  const projName8 = "Domain Lane";
  const projName9 = "Truck Mint";
  const projName10 = "Vitra Mittal";
  const projName11 = "AUTORED";
  const projName12 = "Query Flo";
  const projName13 = "Mister AI";
  const projName14 = "Lawvocate.AI";
  const projName15 = "trekverse";
  const projName16 = "D-STREAK";
  const projName17 = "TOYS'COVE";
  const projName18 = "TeKonecta";
  const projName19 = "Hostguru";

  const projDetail1 =
    "Resume AI transforms resumes with personalized suggestions, stunning designs, and ATS-optimized formatting, making the process revolutionary and user-friendly.";
  const projDetail2 =
    " Dispatch AI transforms logistics, optimizing supply chain efficiency seamlessly from warehouses to petrol stations, ushering in a revolutionary era for streamlined operations.";
  const projDetail3 =
    "Introducing DM Bot an amazing project - revolutionizing direct messaging with state-of-the-art technology, ensuring seamless and efficient communication.";

  const projDetail4 =
    "Revolutionize your business processes with the groundbreaking power of Simpli RFP. Experience unparalleled efficiency and innovation in blockchain and virtual experiences.";

  const projDetail5 =
    "Nexgen is an innovative app with secure APIs, instant conversions, offline modes, and user-friendly interfaces. Reach out to experts for building-related issues or mismanagement.";

  const projDetail6 =
    "Neo AI's virtual infinity is a decentralized metaverse offering 100k land plots for purchase and rent. The platform introduces the VIC token, featuring a 15% daily rental tax and a distinctive advertising opportunity. ";

  const projDetail7 =
    "Metamuze is your go-to marketplace on Solana for NFTs and physical items. Powered by Metaloop Solutions, it's cheat-proof, creator-empowering, and a dynamic hub for secure transactions.";

  const projDetail8 =
    "Embark on a journey to a futuristic digital realm with Domain Lane, where domains and NFTs converge. Bid farewell to the ordinary and welcome the extraordinary in this innovative web3 platform!";
  const projDetail9 =
    "Truck Mint revolutionizes trucking with its innovative approach, featuring Tesla Truck NFTs and securing collaboration through the Commute Bridge. Explore the future of logistics with seamless connectivity and enhanced efficiency.";

  const projDetail10 =
    "Welcome to the world of Vitral Mitral, an extraordinary fusion of artisan structures, contemporary staircases, and cutting-edge glass expertise.";

    const projDetail11 =
    "Unleashing Unparalleled Efficiency and Innovation With Relative Excess Differentiation Our data illuminates the superheroic prowess of artificial intelligence";

    const projDetail12 =
    " Crunch your technical interviews with expert-curated SQL questions covering every analytics domain. Get instant feedback & recommendation on your code, track your progress and walk into any interview with confidence.";

    const projDetail13 =
    " Welcome to Mister AI! Your all-in-one destination for utilizing the incredible potential of artificial intelligence to make your life easier.";

    const projDetail14 =
    " Unlock your Growth with Lawvocate.AI. Your AI-Powered Business and Legal Superpower";

    const projDetail15 =
    " Welcome to the world’s first ever-expanding meta-tourism platform of virtual exploration and adventure, offering travelers the endless possi-bilities to explore and discover.";

    const projDetail16 =
    " Drive Streak (D-streak) is an NFT-based mobility app structured on a drive-to-earn (D2E) model. It is a social-fi application built on the Binance Smart Chain (BSC), providing users the chance to make earnings simply through the activity of daily driving.";

    const projDetail17 =
    " TOYS'COVE is a dynamic e-commerce platform offering a diverse range of high-quality toys for kids, ensuring endless fun and creativity."

    const projDetail18 =
    " Elevate your online presence with teKonecta by unifying your social media profiles, products, and passions in a single link. Share it all with our customizable profile cards your creations, curated content, and sales seamlessly. Connect with the world with one link";
    const projDetail19 =
    " Unlock Hospitality Excellence with Hostguru AI .24/7 AI messaging for short term rental hosts."



    
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className="projectsWrapper">
      <img
        className="glowLeft"
        src="../../../images/about/glowLeft.png"
        alt=""
      />
      <img
        className="glowRight"
        src="../../../images/about/glowRight.png"
        alt=""
      />
      <h2 data-aos="zoom-out-up" className="h-center mt-5">
        {" "}
        <span className="leftSpan_circle">Our </span>&nbsp;Latest Projects{" "}
      </h2>
      <div className="ourProjectContainer">
        <img
          className="btmGlowLeft"
          src="../../../images/about/bottomGlow.png"
          alt=""
        />
        <img
          className="btmGlowRight"
          src="../../../images/about/bottomGlowRight.png"
          alt=""
        />
        <ProjectCards
          cardBg={bgimg1}
          projectName={projName1}
          projectDetails={projDetail1}
           portfolioLink="https://resume-builder-smoky.vercel.app/"
        />
        <ProjectCards
          cardBg={bgimg2}
          projectName={projName2}
          projectDetails={projDetail2}
        />
        <ProjectCards
          cardBg={bgimg3}
          projectName={projName3}
          projectDetails={projDetail3}
          portfolioLink="https://dm-bot-five.vercel.app/"
        />
        <ProjectCards
          cardBg={bgimg4}
          projectName={projName4}
          projectDetails={projDetail4}
            portfolioLink="https://www.simplirfp.com/"
        />
        <ProjectCards
          cardBg={bgimg5}
          projectName={projName5}
          projectDetails={projDetail5}
        />
        <ProjectCards
          cardBg={bgimg6}
          projectName={projName6}
          projectDetails={projDetail6}
        />
        <ProjectCards
          cardBg={bgimg7}
          projectName={projName7}
          projectDetails={projDetail7}
        />
        <ProjectCards
          cardBg={bgimg8}
          projectName={projName8}
          projectDetails={projDetail8}
        />
        <ProjectCards
          cardBg={bgimg9}
          projectName={projName9}
          projectDetails={projDetail9}
        />
        <ProjectCards
          cardBg={bgimg10}
          projectName={projName10}
          projectDetails={projDetail10}
        />
           <ProjectCards
          cardBg={bgimg11}
          projectName={projName11}
          projectDetails={projDetail11}
           portfolioLink="https://auto-red.vercel.app/"
        />
            <ProjectCards
          cardBg={bgimg12}
          projectName={projName12}
          projectDetails={projDetail12}
           portfolioLink="https://queryflo.datatechcon.com/"
        />
             <ProjectCards
          cardBg={bgimg13}
          projectName={projName13}
          projectDetails={projDetail13}
           portfolioLink="https://www.misterai.io/"
        />
        <ProjectCards
          cardBg={bgimg14}
          projectName={projName14}
          projectDetails={projDetail14}
           portfolioLink="https://lawvocate-ai.vercel.app/"
        />
         <ProjectCards
          cardBg={bgimg15}
          projectName={projName15}
          projectDetails={projDetail15}
           portfolioLink="https://trekverse-one.vercel.app/"
        />
          <ProjectCards
          cardBg={bgimg16}
          projectName={projName16}
          projectDetails={projDetail16}
            portfolioLink="https://www.dstreak.io/"
        />
          <ProjectCards
          cardBg={bgimg17}
          projectName={projName17}
          projectDetails={projDetail17}
            portfolioLink="https://toycove-two.vercel.app/"
        />
          <ProjectCards
          cardBg={bgimg18}
          projectName={projName18}
          projectDetails={projDetail18}
           portfolioLink="https://te-konecta-new.vercel.app/"
        />
         <ProjectCards
          cardBg={bgimg19}
          projectName={projName19}
          projectDetails={projDetail19}
           portfolioLink="https://host-guru.vercel.app/"
        />
      </div>
    </div>
  );
};

export default LatestProjects;
